import { Input, Table } from 'reactstrap'
import ColumnSortIcon from '../../../Components/Common/ColumnSortIcon'
import {
  CoursesSortBy,
  ONLINE_COURSE_STATUS,
  OnlineCourseFormatsEnum,
  OnlineCourseItem,
} from '../../../sharedTypes'
import Highlighter from 'react-highlight-words'
import _ from 'lodash'
import moment from 'moment/moment'
import { BadgeColorByStatus, formattedDuration } from '../../../helpers/course'
import React, { useMemo } from 'react'
import { CoursesGridViewProps } from './types'
import { ActionsDropDown } from './CourseActions'
import ShowMoreWithPopover from '../../../Components/Common/ShowMoreWithPopover'

const Columns = [
  {
    name: '',
    style: { width: '3%' },
  },
  {
    name: 'Code',
    sortBy: CoursesSortBy.CODE,
    style: { width: 120 },
  },
  {
    name: 'Name',
    sortBy: CoursesSortBy.NAME,
  },
  {
    name: 'Type',
    sortBy: CoursesSortBy.TYPE,
  },
  {
    name: 'Position',
  },
  // {
  //   name: 'Category',
  //   sortBy: CoursesSortBy.CATEGORY,
  // },
  {
    name: 'Description',
    sortBy: CoursesSortBy.DESCRIPTION,
    // style: { width: '20%' },
  },
  {
    name: 'Duration',
    sortBy: CoursesSortBy.CREDIT_HOURS,
  },
  {
    name: 'Publish Date',
    sortBy: CoursesSortBy.DATE_PUBLISHED,
  },
  {
    name: 'Owner',
    sortBy: CoursesSortBy.OWNER,
  },
  {
    name: 'Status',
    sortBy: CoursesSortBy.STATUS,
  },
  {
    name: 'Actions',
  },
]

const CoursesTableView = ({
  courses,
  query,
  handleDelete,
  handleClone,
  handleSort,
  onSelect,
  selectedEntities,
  permissions,
  isMasteredAdmin,
  setCourseToPreview,
  hasPermissionToActions,
}: CoursesGridViewProps) => {
  const columns = useMemo(() => {
    let _columns = [...Columns]
    if (!permissions.addEdit && !permissions.clone && !permissions.delete) {
      _columns = _columns.filter(column => column.name !== 'Actions')
    }

    if (isMasteredAdmin) {
      _columns = _columns.filter(column => column.name !== '')
    }

    return _columns
  }, [permissions, isMasteredAdmin])

  const handleRowClick = (course: OnlineCourseItem) => {
    if (permissions.addEdit) {
      setCourseToPreview(course)
    }
  }

  return (
    <>
      <div className='table-card pb-3 courses-table'>
        <Table className='align-middle table-nowrap mb-0 mt-3'>
          <thead className='table-light'>
            <tr className='text-muted fs-14'>
              {columns.map(column => (
                <th
                  scope='col'
                  className='align-middle'
                  style={column.style}
                  key={column.name}
                >
                  {column.name}
                  {!!column.sortBy && (
                    <ColumnSortIcon<CoursesSortBy>
                      sortOrder={query.orderBy}
                      sortedColumn={query.sortBy}
                      column={column.sortBy}
                      handleSort={handleSort}
                    />
                  )}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {courses.map((course: OnlineCourseItem, i: number) => (
              <tr
                key={i}
                className='fs-14'
                onClick={() => handleRowClick(course)}
              >
                {!isMasteredAdmin && (
                  <td>
                    <div
                      onClick={e => {
                        e.stopPropagation()
                      }}
                    >
                      <Input
                        className='form-check-input cursor-pointer m-0'
                        type='checkbox'
                        name={`selectCourse${course.id}`}
                        id={`selectCourse${course.id}`}
                        disabled={
                          course.status === ONLINE_COURSE_STATUS.DRAFT ||
                          moment(course.expirationDate).isBefore(
                            moment(),
                            'minute',
                          )
                        }
                        onChange={() => {
                          onSelect(course)
                        }}
                        checked={!!_.find(selectedEntities, { id: course.id })}
                      />
                    </div>
                  </td>
                )}
                <td>
                  <Highlighter
                    highlightClassName='text-highlight'
                    className='text-primary cursor-pointer'
                    searchWords={[query.key || '']}
                    highlightTag={'span'}
                    autoEscape={true}
                    textToHighlight={course.code}
                  />
                </td>
                <td>
                  <div className='d-flex gap-2 align-items-center cursor-pointer'>
                    <div
                      className='text-truncate-two-lines'
                      style={{ maxWidth: 280 }}
                      id={`course-info-${course.id}`}
                    >
                      <Highlighter
                        highlightClassName='text-highlight'
                        className='fw-semibold'
                        searchWords={[query.key || '']}
                        highlightTag={'span'}
                        autoEscape={true}
                        textToHighlight={_.get(
                          course,
                          'translations[0].content.name',
                          '-',
                        )}
                      />
                    </div>
                  </div>
                </td>
                <td className='fw-semibold'>{course?.type.join(', ')}</td>
                <td>
                  <ShowMoreWithPopover
                    limit={1}
                    data={course.positions || []}
                    className='text-body'
                    id={course.id}
                  />
                </td>
                <td>
                  <Highlighter
                    highlightClassName='text-highlight'
                    id={'courseDescription' + course.id}
                    className='text-truncate-two-lines course-description text-muted'
                    style={{ maxWidth: 180 }}
                    searchWords={[query.key || '']}
                    highlightTag={'span'}
                    autoEscape={true}
                    textToHighlight={_.get(
                      course,
                      'translations[0].content.description',
                      '-',
                    )}
                  />
                </td>
                <td>
                  <span className='badge badge-soft-grey fs-12 fw-normal'>
                    {!(course.formats || []).includes(
                      OnlineCourseFormatsEnum.COMPETENCY,
                    )
                      ? formattedDuration(course.duration)
                      : '-'}
                  </span>
                </td>
                <td>
                  <span className='badge badge-soft-grey fs-12 fw-medium text-capitalize'>
                    {course.publishedDate
                      ? moment(course.publishedDate).format('MM/DD/YYYY')
                      : '-'}
                  </span>
                </td>
                <td>
                  <div className='d-flex align-items-center'>
                    <div className='flex-shrink-0'>
                      {!course.createdBy?.companyId && (
                        <div className='avatar-xs'>
                          <div className='avatar-title rounded-circle bg-soft-primary text-primary'>
                            M
                          </div>
                        </div>
                      )}
                    </div>

                    <div className='flex-grow-1 ms-2'>
                      <p className='card-title mb-0 fs-14'>
                        {course.createdBy?.firstName}{' '}
                        {course.createdBy?.lastName}
                      </p>
                    </div>
                  </div>
                </td>
                <td>
                  {hasPermissionToActions(course.companyId as number) && (
                    <span
                      className={`badge badge-soft-${
                        BadgeColorByStatus[course.status]
                      } fs-12 fw-medium text-capitalize`}
                    >
                      {course.status}
                    </span>
                  )}
                </td>
                <td>
                  {course.createdBy &&
                    hasPermissionToActions(course.companyId) && (
                      <ActionsDropDown
                        course={course}
                        permissions={permissions}
                        onClone={handleClone}
                        onDelete={handleDelete}
                      />
                    )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </>
  )
}

export default CoursesTableView
